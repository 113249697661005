/* Estilos para las flechas parpadeantes */
.extra-paragraph {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
}

.arrow {
  font-size: 24px;
  margin: 0 10px;
  animation: blink 1s linear infinite;
}

.left-arrow {
  animation-delay: 0.5s; /* Retraso de animación para la flecha izquierda */
}

.right-arrow {
  animation-delay: 1s; /* Retraso de animación para la flecha derecha */
}

@keyframes blink {
  0%, 50% {
    opacity: 1;
  }
  51%, 100% {
    opacity: 0;
  }
}

/* Estilos para las flechas hacia abajo */
.arrow {
  font-size: 28px; /* Tamaño de fuente deseado */
  color: white; /* Color de las flechas */
  margin: 0 10px; /* Espacio entre las flechas y el texto */
}

/* Estilo para el texto en negrita y color blanco */
.bold {
  
  color: white;
}

/* Alinea verticalmente el contenido del párrafo adicional */
.extra-paragraph {
  display: flex;
  align-items: center;
}

